.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    width: 100%
}
.flexColumn{
    display: flex;
    max-width: 100%;
    min-width: 1024px;
    flex-direction: column;
    justify-content: center;
    align-self: center;

} 

@media screen and (max-width: 1024px) {
    .flexColumn{
        min-width: 200px;
        max-width: 100%;
    }
    input {
        min-width: 200px;
        max-width: 100%;
    } 
    textarea{
        min-width: 200px;
        max-width: 100%;
        width: 400px;
    }
}

.contact-us{
    align-self: center;
    width: 100%;
    display:flex;
    flex-direction: column;
    text-align: left;
}

input{
    outline: none;
    width: 100%;
    min-width: 200px;
    max-width: 400px;
    height: 2.0rem;
    padding: 3px;
    border: 2px solid rgb(185, 185, 185);
    border-radius: 4px;
    font-size: 1.25rem;
}

textarea{
    display: block;
    width: 90%;
    min-width:400px;
    max-width: 400px;
    overflow: hidden;
    resize: both;
    min-height: 40px;
    line-height: 20px;
    font-size: 1.5rem;
}
textarea[contenteditable]:empty::before {
    content: "Placeholder still possible";
    color: gray;
  }

#teamworkimg{
    max-width: 500px;
    max-height: 500px;
    box-shadow: 0 10px 10px 2px rgba(0,0,0,0.2);
    transition: 0.3s;
}

.content-row{
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-content: space-between;
    margin: 10px;
    padding: 10px;
}

label{
    font-size: 1.25rem;
    width: 55%;
}

select{
    outline: none;
    font-size: 1.25rem;
    height: 1.75rem;    
    width: 400px; 
    min-width: 300px;
    border: 2px solid rgb(185, 185, 185);
    border-radius: 4px;
}

option{
    font-size: 1.25rem
}

button{

    margin: 2rem;
    font-size: 1.25rem;
    font-weight: 600;
    width: 100px;

    
}